

<div class="dialog-container">
    <div class="header">
      <h2 class="title">Edit Image</h2>
      <button [mat-dialog-close]="false" class="x-mark"><i class="fa-solid fa-xmark"></i></button>
    </div>
  
    <div class="dialog-content">
        <div class="buttons">
            <div class="button">
                <button  class="mr-3" mat-fab aria-label="Rotate Button" (click)="rotate()">
                    <mat-icon>rotate_right</mat-icon>
                </button> {{'gen_rotate' | translate}}
            </div>

            <div class="button">
                <button class="mr-3" mat-fab aria-label="Flip Horizontal Button" (click)="flipHorizontal()">
                    <mat-icon>swap_horizontal_circle</mat-icon>
                </button> {{'gen_flip_horizontal' | translate}}
            </div>

            <div class="button">
                <button class="mr-3" mat-fab aria-label="Flip Vertical Button" (click)="flipVertical()">
                    <mat-icon>swap_vertical_circle</mat-icon>
                </button> {{'gen_flip_vertical' | translate}}
            </div>

            <!-- <div class="button">
                <button class="mr-3" mat-fab aria-label="Flip Vertical Button" (click)="scaleIn()">
                    <mat-icon>zoom_in</mat-icon>
                </button> {{'gen_zoom_in' | translate}}
            </div>

            <div class="button">
                <button class="mr-3" mat-fab aria-label="Flip Vertical Button" (click)="scaleOut()">
                    <mat-icon>zoom_out</mat-icon>
                </button> {{'gen_zoom_out' | translate}}
            </div> -->
        </div>
        <div class="col-md-8 cropper-container">
            <image-cropper 
                [cropperMinWidth]="settings!.minWidth" 
                [cropperMinHeight]="settings!.minHeight"
                [cropperMaxWidth]="settings!.maxWidth" 
                [cropperMaxHeight]="settings!.maxHeight" 
                [transform]="transform"
                [canvasRotation]="canvasRotation" 
                [imageChangedEvent]="data.imageFile" 
                [maintainAspectRatio]="true"
                [aspectRatio]="settings!.aspectRatio" format="png" 
                (imageCropped)="imageCropped($event)"
                (loadImageFailed)="imageFailed()">
            </image-cropper>
        </div>
    </div>
  
    <!-- Footer - Buttons -->
    <div mat-dialog-actions class="footer">
      <button mat-button [mat-dialog-close]="false">Cancel</button>
      <button mat-button mat-raised-button color="primary" (click)="save()">Save</button>
    </div>
  </div>