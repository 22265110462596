import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CountryCode, CountryCodes } from './country-codes';
import { Periods } from '@andreasmeis/period-picker';
import * as moment from 'moment';


@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private currentLang: any;
  private languages: any;

  constructor(private translate: TranslateService) { }

  /** Set language.
  * @param lang Language code e.g. en,el
  */
  setLanguage(lang: string) {
    this.currentLang = lang;
    this.changeUILang(lang);
  }

  /** Get current language.
  * @return {String} Language code e.g. en,el
  */
  getLanguage() {
    return this.currentLang ? this.currentLang : this.getBrowserLang();
  }

  /** Set available languages.
  * @param {Array} Language codes e.g. ["en","el"]
  */
  setLanguages(langs: string[]) {
    this.languages = langs && langs.length ? langs : ["en"];
  }

  /** Get current language.
  * @return {String[]} Language codes e.g. ["en","el"]
  */
  getLanguages() {
    return this.languages;
  }

  /** Get default language.
  * @return {String} Language code e.g. en,el
  */
  getDefaultLanguage() {
    return this.languages && this.languages.length ? this.languages[0] : "en";
  }

  /** Setting language when the app is initialize */
  initLang() {
    this.changeUILang(this.currentLang);
  }

  /** Change UI language by getting the proper translate file
   * @param lang Language code e.g. en,el
  */
  changeUILang(lang: any) {
    lang = lang ? lang : this.getBrowserLang();
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
  }

  /** Get browser language
   * @returns language code e.g. en,el
  */
  getBrowserLang() {
    return this.translate.getBrowserLang();
  }

  /** Find language info for a language code
   * @param langCode language code to search e.g. en, el
   * @param isoCode isoCode e.g. iso639_1,iso639_2
   *  @returns language name e.g. English
  */
  findLanguageInfo(langCode: string, isoCode: string) {
    const countryCodes: Array<CountryCode> = CountryCodes;
    const result = countryCodes.find((obj: any) => obj[isoCode] == langCode);
    if (result) {
      return result.name;
    } else {
      return ""
    }
  }

  getPeriods(): any[] {
    return [
      {
        text: 'last_week',
        startDate: Math.floor(new Date(moment().startOf("day").subtract(7, 'days').toLocaleString()).getTime() / 1000),
        endDate: Math.floor(new Date(moment().endOf("day").toLocaleString()).getTime() / 1000)
      },
      {
        text: 'last_month',
        startDate: Math.floor(new Date(moment().startOf("day").subtract(30, 'days').toLocaleString()).getTime() / 1000),
        endDate: Math.floor(new Date(moment().endOf("day").subtract(0, 'days').toLocaleString()).getTime() / 1000)
      },
      {
        text: 'last_year',
        startDate: Math.floor(new Date(moment().startOf("day").subtract(365, 'days').toLocaleString()).getTime() / 1000),
        endDate: Math.floor(new Date(moment().endOf("day").subtract(0, 'days').toLocaleString()).getTime() / 1000)
      }
    ];
  }

  setCurrentPeriod(period: any): any {
    if (period.text.includes('Custom Range')) {
      period.startDate = Math.floor(new Date(moment(period.startDate).startOf("day").toLocaleString()).getTime() / 1000);
      period.endDate = Math.floor(new Date(moment(period.endDate).endOf("day").toLocaleString()).getTime() / 1000);
    }
    return period;
  }

  /**  Get the proper translation and display a snak bar
   * @param key translation key
   * @param params translation object params in case a case has params
  */
  /* openSnackBar(key, params) {
    let self = this;
    this.translate.get(key, params).subscribe((text: string) => {
      //Display Toast with the error
      self.matSnackBar.open(text, "OK", {
        duration: 5000,
      });
    });
  } */
}
