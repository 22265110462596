<div class="container-fluid sub-top-bar">
    <div class="row">
        <div class="col-sm-5">
            <!-- Search Input -->
            <mat-form-field class="search-field" appearance="outline">
                <mat-label>{{"gen_search"|translate}}</mat-label>

                <input matInput (keyup.enter)="searchPolls()" placeholder="{{'search_by_title'|translate}}" #searchField
                    [(ngModel)]="searchValue">
                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>

            <!-- Filter selection -->
            <mat-form-field appearance="outline">
                <mat-label>{{"options_requestStatus"|translate}}</mat-label>
                <mat-select [(ngModel)]="statusValue" (selectionChange)="searchPolls()">
                    <mat-option>{{"options_noStatus"|translate}}</mat-option>
                    <mat-option *ngFor="let item of status" [value]="item.value">
                        {{item.viewValue|translate}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-sm-7 pt-3">
            <soeasytv-period-picker class="date-picker-so-easy-tv" [periodList]="periods"
                (periodChanged)="periodChange($event)"></soeasytv-period-picker>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row polls-table-container">
        <div class="col-md-6 py-3">
            <h4>{{"polls"|translate}}</h4>
            <p *ngIf="datasource?.length">{{"polls_total"|translate}} {{datasource?.length}}</p>
        </div>

        <div class="col-md-6 py-3 text-end">
            <button mat-raised-button color="primary" (click)="onCreatePoll()">
                <i class="fas fa-solid fa-plus me-2 align-baseline "></i> {{"poll_create_poll" |translate}}
            </button>
        </div>
        <div class="col-md-12">
            <table class="w-100" mat-table [dataSource]="datasource">
                <!-- Title -->
                <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef> {{"gen_title"|translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.title}} </td>
                </ng-container>

                <!-- Status -->
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef class="text-center"> {{"gen_status"|translate}} </th>
                    <td mat-cell *matCellDef="let element" class="text-center">
                        <span class="badge" [ngClass]="('status-' + element.status)">
                            {{'status_' + element.status | translate}}
                        </span>
                    </td>
                </ng-container>

                <!-- Actions -->
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef class="action-th" class="text-center">
                        {{"gen_actions"|translate}}
                    </th>
                    <td mat-cell *matCellDef="let element" class="text-center">
                        <div class="icons">
                            <i class="fas fa-pencil-alt mx-2" [matTooltip]="'gen_edit'| translate"
                                (click)="onEditPoll(element.pollId)"></i>
                            <i class="fas fa-clone mx-2" [matTooltip]="'poll_create_dublicate'| translate"
                                (click)="onCreateDublicate(element)"></i>
                            <i *ngIf="element.status==1" class="fas fa-chart-line mx-2"
                                [matTooltip]="'poll_open_charts'| translate" (click)="onOpenCharts(element)"></i>
                            <i class="fas fa-rocket mx-2"
                                [matTooltip]="(element.status == 1 ? 'poll_republish' : 'poll_publish') | translate"
                                (click)="openModal(element)"></i>
                            <i class="fas fa-gift mx-2" [matTooltip]="'poll_add_draw' | translate"
                                (click)="addDraw(element)"
                                [ngClass]="{'disable-i': (element.isActive || element.status == 2) }"></i>
                            <i class="fas fa-arrow-circle-up mx-2" [matTooltip]="'poll_send_sse' | translate"
                                [ngClass]="{'disable-i': (element.isActive || element.status == 2) }"
                                (click)="sendSSE(element.pollId)"></i>
                        </div>
                    </td>
                </ng-container>

                <!-- Select -->
                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox class="custom-frame" (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue()" [indeterminate]="selection.hasValue() && !isAllSelected()">
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <mat-checkbox class="custom-frame" (change)="$event ? selection.toggle(element): null"
                            (click)="$event.stopPropagation()" [checked]="selection.isSelected(element)">
                        </mat-checkbox>
                    </td>
                </ng-container>

                <!-- Delete -->
                <ng-container matColumnDef="delete">
                    <th mat-header-cell *matHeaderCellDef class="w-25">
                        <button *ngIf="isSomeSelected()" [@fadeInOut] mat-flat-button color="warn" (click)="onDelete()">
                            <i class="far fa-trash-alt"> </i>
                            <span class="ps-1">
                                {{ "gen_delete"|translate}}
                            </span>
                        </button>
                    </th>
                    <td mat-cell *matCellDef="let element"></td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <h4 class="text-center py-3" *ngIf="!datasource?.length">{{"pollsTable_noPolls" | translate}}</h4>
        </div>
    </div>
</div>