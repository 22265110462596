import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-stats',
  templateUrl: './table-stats.component.html',
  styleUrls: ['./table-stats.component.less']
})
export class TableStatsComponent implements OnInit {
  @Input('poll') poll!: any;
  @Input() config: any
  data = new Array();
  totalValue: number = 0;
  displayedColumns: string[] = ['answer', 'value', 'percentage'];
  constructor() { }

  ngOnInit(): void {
    this.totalValue = this.config['series'].reduce((a:number, b:number) => a + b, 0)
    this.data = this.config['labels'].map((resp: any, i: number) => {
      return {
        answer: resp,
        value: this.config['series'][i],
        percentage: this.findPercentage(this.config['series'][i], this.totalValue)
      }
    })
  }


  findPercentage(value: number, totalValue: number): any {
    const percentage = parseFloat(((value / totalValue) * 100).toFixed(1));
    console.log(percentage);
    return percentage.toString().length == 2 ? percentage.toString() + '.0%' :( percentage ? percentage + '%' : '0%');
  }

}
