<div class="container" [formGroup]="fg">
    <div class="create-poll-container">
        <div class="row">
            <div class="col-md-6 py-3">
                <h4>{{"poll_create_poll"|translate}}</h4>
                <p>{{"poll_create_poll_text"|translate}}</p>
            </div>
            <div class="col-md-6 py-3 text-end">
                <button class="me-2" [disabled]="disableButtons" mat-raised-button color="primary" *ngIf="forTheFuture" (click)="onSaveAsDraft()">
                    {{"gen_save"|translate}}
                </button>
                <button class="me-2" [disabled]="disableButtons" mat-raised-button color="primary" *ngIf="!forTheFuture" (click)="onSubmit()">
                    {{"poll_publish"|translate}}
                </button>
                <button [disabled]="disableButtons" mat-raised-button type="button" *ngIf="!forTheFuture" (click)="onSaveAsDraft()">
                    {{"poll_save_as_draft"|translate}}
                </button>
            </div>
        </div>
        <div class="form-container">
            <div class="row">
                <!-- Poll Image -->
                <div class="text-center" style="font-size: 18px;">Poll Image</div>
                <div class="col-sm-12 poll-image">
                    
                    <div class="card shadow p-1 mb-4" [ngClass]="{'disable-button': disableButtons}">
                        
                        <div class="card-body" >
                          <img class="w-100 " [src]="pollImageUrl ? pollImageUrl : placeHolder" />
                          <div class="edit">                    
                            <button class="mr-3" mat-fab color="primary" aria-label="Flip Horizontal Button" [disabled]="disableButtons">
                              <mat-icon *ngIf="pollImageUrl">edit</mat-icon>
                              <mat-icon *ngIf="!pollImageUrl">add</mat-icon>
                            </button>
                            <button *ngIf="pollImageUrl" class="mr-3" mat-fab color="primary" aria-label="Flip Horizontal Button" style="position: absolute;right:12.5rem;"  (click)="deleteImagePoll('poll')" [disabled]="disableButtons">
                                <mat-icon *ngIf="pollImageUrl">delete</mat-icon>
                              </button>
                            <input  type="file" accept="image/*" (change)="fileChangeEvent($event,'poll')" [disabled]="disableButtons" />
                          </div>
                        </div>
                      </div>
                </div>
                <!-- Poll Title -->
                <div class="col-sm-4">
                    <label for="title">{{"gen_title"|translate}}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput type="text" formControlName="title" [placeholder]="'poll_title' |translate" id="title" />
                        <mat-error>{{"poll_title_error"|translate}}</mat-error>
                    </mat-form-field>
                </div>

                <!--Poll contentIds-->
                <div class="col-sm-4">
                    <label for="duration">{{"poll_contentIds"|translate}}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input id="duration" matInput type="text" formControlName="contentIds" [placeholder]="'poll_contentIds_placeholder'|translate" />
                        <mat-error>{{"poll_contentsIds_error"|translate}}</mat-error>
                    </mat-form-field>
                </div>

                <!-- Poll autoResult -->
                <div class="col-sm-4">
                    <label for="autoResults">{{"poll_auto_display_screen_label"|translate}}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input matInput type="text" style="display: none;" [disabled]="disableButtons" />
                        <mat-slide-toggle id="autoResults" formControlName="autoResults">
                          {{ 'poll_auto_display_screen_description' | translate }}
                          {{ fg.get('autoResults')?.value ? ('poll_auto_display_screen_enabled' | translate) : ('poll_auto_display_screen_disabled' | translate) }}
                        </mat-slide-toggle>
                      </mat-form-field>
                    <!-- <div>
                        <label for="duration">{{"poll_auto_display_screen_label"|translate}}</label>
                    </div>
                    <div class="w-100 slide-auto-show">
                        <mat-slide-toggle [(ngModel)]="isChecked" formControlName="autoResults">{{'poll_auto_display_screen_description' | translate }} {{isChecked ? ('poll_auto_display_screen_enabled' | translate ): ('poll_auto_display_screen_disabled' | translate)}}</mat-slide-toggle>
                    </div> -->
                </div>

                <!-- Poll Duration -->
                <div class="col-sm-4">
                    <label for="contentIDs">{{"poll_duration"|translate}}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input id="contentIDs" matInput type="text" formControlName="duration" [placeholder]="'poll_duration_placeholder'|translate" />
                        <mat-error>{{"poll_duration_error"|translate}}</mat-error>
                    </mat-form-field>
                </div>

                <!-- Poll Result Duration -->
                <div class="col-sm-4">
                    <label for="contentIDs">{{"poll_result_duration"|translate}}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <input id="contentIDs" matInput type="text" formControlName="resultsDuration" [placeholder]="'poll_result_duration_placeholder'|translate" />
                        <mat-error>{{"poll_result_duration_error"|translate}}</mat-error>
                    </mat-form-field>
                </div>              
                
                <!-- scheduled with date / time picker -->
                <div class="col-sm-12 schedule-period" class="schedule-period">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col mt-3">
                                <mat-checkbox [checked]="forTheFuture" (change)="changeSchedule($event)" [disabled]="disableButtons">Scheduled?</mat-checkbox>
                            </div>
                            <div class="col">
                                <mat-form-field class="date-picker" appearance="outline" [hidden]="!forTheFuture">
                                    <mat-label>Choose a date</mat-label>
                                    <input matInput [matDatepicker]="picker" [formControl]="currentDate" (dateChange)="changeDate($event)" placeholder="DD/MM/YYYY" [min]="today" [disabled]="true">
                                    <!-- <mat-hint>DD/DD/YYYY</mat-hint> -->
                                    <mat-datepicker-toggle matIconSuffix [for]="picker" [disabled]="disableButtons"></mat-datepicker-toggle>
                                    <mat-datepicker #picker [disabled]="disableButtons"></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="col time">
                                <div>
                                    <mat-form-field class="minutes-picker" appearance="outline" [hidden]="!forTheFuture">
                                        <mat-label>Hour</mat-label>
                                        <mat-select matNativeControl (selectionChange)="changeTime($event,'hour')" [(value)]="currentHour" [disabled]="disableButtons">
                                            <mat-option *ngFor="let hour of hours" [value]="hour">
                                                {{hour}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <span class="ml-2 mr-2 partition-text" [hidden]="!forTheFuture">:</span>
                                <div>
                                    <mat-form-field class="minutes-picker" appearance="outline" [hidden]="!forTheFuture">
                                        <mat-label>Minutes</mat-label>
                                        <mat-select matNativeControl (selectionChange)="changeTime($event,'minutes')" [(value)]="currentMinutes" [disabled]="disableButtons">
                                            <mat-option *ngFor="let minute of minutes" [value]="minute">
                                                {{minute}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Poll Question and Answers -->
                <div class="col-sm-10">
                    <mat-tab-group formGroupName="translations" [(selectedIndex)]="selectedTab" >
                        <mat-tab label="{{language.name}}" *ngFor="let language of languages; let i=index" [formGroupName]="i" >
                            <!-- Question -->
                            <div class="pt-4 w-100">
                                <label for="question">{{"poll_question"|translate}}</label>
                                <mat-form-field appearance="outline" class="w-100">
                                    <input id="question" matInput type="text" formControlName="question" [placeholder]="'poll_question_placeholder'|translate" />
                                    <mat-error *ngIf="translations.controls[i].get('question')?.hasError('required')">{{"poll_question_error"|translate}}</mat-error>
                                    <mat-error *ngIf="translations.controls[i].get('question')?.hasError('maxlength')">{{"poll_question_maxlength_error"|translate}}</mat-error>
                                </mat-form-field>
                            </div>

                            <!-- Answers -->
                            <div formArrayName="answers">
                                <div class="answers-form pt-3">
                                    <label for="question">{{"poll_answer_option"|translate}}</label>
                                    <div *ngFor="let answer of formAnswer(i).controls; let j = index">
                                        <div [formGroupName]="j">
                                            <mat-form-field appearance="outline" class="w-100 pt-2">
                                                <mat-label> {{"poll_answers"|translate}} {{ j + 1 }}:
                                                </mat-label>
                                                <input required matInput type="text" formControlName="answer" [placeholder]="'poll_answers_placeholder'|translate" />
                                                <mat-error>{{"poll_answers_error"|translate}}</mat-error>
                                                <!-- Remove Answer -->
                                                <button [disabled]="disableButtons" mat-icon-button (click)="removeAnswer(j)" matSuffix [matTooltip]="'poll_delete_answer'|translate">
                                                    <mat-icon matSuffix>delete</mat-icon>
                                                </button>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Add Answer Button -->
                            <button mat-raised-button [disabled]="disableButtons" color="primary" [matTooltip]="'poll_add_answer'|translate" type="button" (click)="addAnswer()">
                                <i class="fas fa-solid fa-plus me-2 align-baseline"></i>
                                {{"poll_add_answer"|translate}} </button>
                        </mat-tab>
                    </mat-tab-group>
                </div>
                <div id="container-image-answer" class="col-sm-2">
                    <!-- Give extra space from question -->
                    <div style="width: 200px; height: 12.5rem;padding: 12px 3rem;">Image</div>
                    <!-- create object image -->
                    <div class="image-answer" *ngFor="let item of answerImage; let i=index" [ngStyle]="{'opacity': disableButtons ? '0.5' : '1'}">
                        <img [src]="item.url? item.url : placeHolder" alt="image">
                        <div class="edit">                    
                            <button class="mr-3" mat-fab color="primary" aria-label="Flip Horizontal Button" [disabled]="disableButtons">
                              <mat-icon *ngIf="item.url">edit</mat-icon>
                              <mat-icon *ngIf="!item.url">add</mat-icon>
                            </button>
                            <button *ngIf="item.url" class="mr-3" mat-fab color="primary" aria-label="Flip Horizontal Button" style="position: absolute;left: 0; top: -42px;"  (click)="deleteImagePoll('answer', i)" [disabled]="disableButtons">
                                <mat-icon *ngIf="item.url">delete</mat-icon>
                              </button>
                            <input  type="file" accept="image/*" (change)="fileChangeEvent($event, 'answer' , i)" [disabled]="disableButtons" />
                          </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>