import { Injectable } from '@angular/core';
import { RequestService } from '../requestService/request.service';
import { UntypedFormArray } from '@angular/forms';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PollService {

  constructor(private requestService: RequestService) { }

  searchPolls(params: any): Promise<{ data: any }>  {
    let searchParams: any = {
      url: 'Polls/searchPolls',
      req: {}
    }

    if (params.searchValue) {
      searchParams.req["title"] = params.searchValue;
    }

    if (params.statusValue || params.statusValue == 0) {
      searchParams.req["status"] = params.statusValue;
    }

    if (params.selectedPeriod) {
      searchParams.req["from"] = params.selectedPeriod.startDate;
      searchParams.req["to"] = params.selectedPeriod.endDate;
    }
    return new Promise(resolve => {
      this.requestService.mainPostRequest(searchParams).then((resp : any) => {
        resolve({data: resp.data});
      })
    });
  }

  publishPoll(id: number): Promise<boolean>  {
    const publishParams = {
      url: `Polls/publishPoll?pollId=${id}`
    }
    return new Promise(resolve => {
      this.requestService.mainGetRequest(publishParams).then((resp : any) => {
        resolve(resp.success);
      })
    });
  }

  deletePoll(deleteArray: Array<Number>): Promise<boolean>  {
    const deleteParams: any = {
      url: 'Polls/deletePolls',
      req: [...new Set(deleteArray)]
    }

    return new Promise(resolve => {
      this.requestService.mainPostRequest(deleteParams).then((resp : any) => {
        resolve(resp.success);
      })
    });
  }

  getPoll(id: string): Promise<{ data: any }>  {
    let params: any = {
      url: `Polls/getPoll?pollId=${id}`
    }

    return new Promise(resolve => {
      this.requestService.mainGetRequest(params).then((resp : any) => {
        resolve({data: resp.data});
      })
    });
  }

  editPoll(params: any): Promise<{ data: any }>  {
    let poll: any = {
      url: "Polls/editPoll",
      req: {
        id: params.id,
        title: params.title,
        pollStatus: params.status,
        scheduledEpoch: params.status == 2 ? (params.scheduledEpoch / 1000) : null,
        contentIds: params.contentIds,
        duration: params.duration,
        resultsDuration: params.resultsDuration,
        autoResults: params.autoResults,
        question: {
          translations: this.getQuestions(params.translations)
        },
        answers: this.getAnswers(params.translations)
      }
    }

    return new Promise(resolve => {
      this.requestService.mainPostRequest(poll).then((resp: any) => {
        let uploadPromises: Promise<any>[] = [];
        // Poll Image
        if (params?.imagePoll?.file) {
          uploadPromises.push(this.uploadImage(params.imagePoll.file, 'poll', params.id));
        } else if (params?.imagePoll?.deleted) {
          uploadPromises.push(this.deleteImage('poll', params.id));
        }
        // Answers Images
        params?.imageAnswer?.forEach((answer: any, i: number) => {
            if (answer?.file) {
              uploadPromises.push(this.uploadImage(answer.file, 'answer', resp.data.pollAnswers[i].pollAnswerId));
            }
            if (answer?.deleted == true) {
              uploadPromises.push(this.deleteImage('answer', resp.data.pollAnswers[i].pollAnswerId));
            }
        });
        Promise.all(uploadPromises).then(() => {
          resolve({ data: resp.data });
        });       
      })
    });
  }
  uploadImage(file: any, type: string, id: string): Promise<any>{
    return new Promise(resolve => {
      const formData = new FormData();
      formData.append('File', file);
      formData.append('Type', type);
      formData.append('id', id);
      this.requestService.upload({
        url: 'polls/uploadImage',
        req: formData,
        type: 'FormData'
      }).subscribe(resp => {
        resolve(resp);
      });
    });   
  }

  deleteImage(type: string, id: string): Promise<any> {
    return new Promise(resolve => {
      const formData = new FormData();
      formData.append('Type', type);
      formData.append('id', id);
      this.requestService.upload({
        url: 'polls/deleteImage',
        req: formData,
        type: 'FormData'
      }).subscribe(resp => {
        resolve(resp);
      });
    });
  }

  createImageFile(imgUrl: string): Observable<any>{
    return this.requestService.downloadAndStoreImage(imgUrl);
  }

  createPoll(params: any): Promise<{ data: any }>  {
    let poll: any = {
      url: 'Polls/createPoll',
      req: {
        title: params.title,
        pollStatus: params.status,
        scheduledEpoch: params.status == 2 ? (params.scheduledEpoch / 1000 ): null,
        contentIds: params.contentIds,
        duration: params.duration,
        resultsDuration: params.resultsDuration,
        autoResults: params.autoResults,
        question: {
          translations: this.getQuestions(params.translations)
        },
        answers: this.getAnswers(params.translations)
      }
    }

    return new Promise(resolve => {
      this.requestService.mainPostRequest(poll).then((resp: any) => {
        let uploadPromises: Promise<any>[] = [];
        if (params?.imagePoll?.file)
          uploadPromises.push(this.uploadImage(params.imagePoll.file, 'poll', resp.data.pollId));        
        params?.imageAnswer?.forEach((answer: any, i: number) => {
          if (answer?.file) {
            uploadPromises.push(this.uploadImage(answer.file, 'answer', resp.data.pollAnswers[i].pollAnswerId));
          }
        });
        
        Promise.all(uploadPromises).then(() => {
          resolve({ data: resp.data });
        }); 
      })
    });
  }

  // get Questions from form
  getQuestions(formArray: UntypedFormArray) {
    return formArray.value.map((x: any) => {
      return {
        language: x.langId,
        text: x.question
      }
    })
  }

  // get Answers from form
  getAnswers(formArray: UntypedFormArray) {
    let array: any = [];
    for (let j = 0; j < formArray.value[0].answers.length; j++) {
      array.push({
        order: j, translations: [], ...(formArray.value[0].answers[j].id && { id: formArray.value[0].answers[j].id })
      });
      formArray.value.map((translation: any) => {
        let langAnswers = formArray.value.find((trans: any) => trans.langId === translation.langId
        )
        array[j].translations.push({
          language: translation.langId,
          text: langAnswers.answers[j].answer
        })
      })
    }
    return array
  }

  // Send SSE event
  sendSSE(id: String): Promise<{ data: any }> {
    let params = {
      url: 'Polls/publishStatistics?PollId=' + id
    };
    return new Promise(resolve => {
      this.requestService.mainGetRequest(params).then((resp: any) => {
        if (resp.success) {
          this.requestService.openSnackBar('Results Sent', 'OK');
        }
        resolve({data: resp});
      });
    });
  }


  
}
