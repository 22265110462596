import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageTransform, base64ToFile, ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-image-editor-dialog',
  templateUrl: './image-editor-dialog.component.html',
  styleUrls: ['./image-editor-dialog.component.less']
})
export class ImageEditorDialogComponent {
  imageChangedEvent: any = '';
  compressed: any;
  canvasRotation = 0;
  scale = 1;
  settings;
  transform: ImageTransform = {
      scale: 1
  };
  constructor(public dialogRef: MatDialogRef<ImageEditorDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
      this.settings = this.getRatioSizes();;
  }

  /** Save action close dialog and return croppedImage*/
  save() {
      this.dialogRef.close({
          success: true,
          file: base64ToFile(this.compressed),
          source: this.compressed
      });
  }

  /** Event trigger when user cropps an image  */
  imageCropped(event: ImageCroppedEvent) {
      this.compressImage(event.base64, this.settings.minWidth, this.settings.minHeight).then((exportResult: any) => {
          this.compressed = exportResult;
      }, error => {
          this.compressed = event.base64;
      });
  }

  /** Return proper value based on ratio  */
  getRatioSizes() {
      switch (this.data.ratio) {
          case "1:1":
              return {
                  minWidth: 400,
                  minHeight: 400,
                  maxWidth: 2000,
                  maxHeight: 2000,
                  aspectRatio: 1 / 1
              }
              break;
          case "2:3":
              return {
                  minWidth: 800,
                  minHeight: 1200,
                  maxWidth: 4000,
                  maxHeight: 6000,
                  aspectRatio: 2 / 3
              }
              break;
          case "16:9":
              return {
                  minWidth: 1920,
                  minHeight: 1080,
                  maxWidth: 5760,
                  maxHeight: 3240,
                  aspectRatio: 16 / 9
              }
              break;
          default:
              return {
                  minWidth: 400,
                  minHeight: 400,
                  maxWidth: 2000,
                  maxHeight: 2000,
                  aspectRatio: 1 / 1
              }
      }
  }

  /** Event trigger when image failed to loaded from image cropper */
  imageFailed() {
      this.dialogRef.close({ success: false })
  }

  /** Check if the ratio of the image is valid
   * @param ratio: 16:9 or 2:3
   * @param imageWidth: original width of the image
   * @param imageHeight: original height of the image
   * @returns Boolean
  */
  checkRatio(ratio: any, imageWidth: any, imageHeight: any) {
      let adjustWidth: any, adjustHeight: any, originalWidth: any, originalHeight: any;
      if (ratio == "16:9") {
          originalWidth = 1920;
          originalHeight = 1080;
      } else if (ratio == "2:3") {
          originalWidth = 800;
          originalHeight = 1200;
      }
      adjustWidth = (originalWidth / originalHeight) * imageHeight;
      adjustHeight = imageWidth / (originalWidth / originalHeight);

      return adjustWidth == imageWidth && adjustHeight == imageHeight;
  }

  /** Compress image into specific size  */
  compressImage(src: any, newX: any, newY: any) {
      return new Promise((res, rej) => {
          const img = new Image();
          img.src = src;
          img.onload = () => {
              const elem = document.createElement('canvas');
              elem.width = newX;
              elem.height = newY;
              const ctx = elem.getContext('2d');
              ctx?.drawImage(img, 0, 0, newX, newY);
              const data = ctx?.canvas.toDataURL(this.data.exportType ? this.data.exportType : 'image/jpeg');
              res(data);
          }
          img.onerror = error => rej(error);
      })
  }

  /** Flip image horizontaly */
  flipHorizontal() {
      this.transform = {
          ...this.transform,
          flipH: !this.transform.flipH
      };
  }

  /** Flip image vertical */
  flipVertical() {
      this.transform = {
          ...this.transform,
          flipV: !this.transform.flipV
      };
  }

  /** Rotate image  */
  rotate() {
      this.canvasRotation++;
      const flippedH = this.transform.flipH;
      const flippedV = this.transform.flipV;
      this.transform = {
          ...this.transform,
          flipH: flippedV,
          flipV: flippedH
      };
  }

  scaleOut() {
      this.scale -= .1;
      this.transform = {
          ...this.transform,
          scale: this.scale
      };
  }

  scaleIn() {
      this.scale += .1;
      this.transform = {
          ...this.transform,
          scale: this.scale
      };
  }
}
