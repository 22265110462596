export const environment = {
  production: false,
  apiUrl: "https://polls-stage.cine.gr/cms/",
  placeHolderPath: '/assets/images/placeholder.png',
  idsUrl: "https://account-stage.cine.gr/ids/",
  appVersion: "v0.2.0",
  theme: {
    pallete: {
      mode: 'light',
      palette: 'palette1',
      monochrome: {
        enabled: true,
        color: '#349400',
        shadeTo: 'light',
        shadeIntensity: 0.9
      }
    }
  }
};