import { SelectionModel } from '@angular/cdk/collections';
import { Component, } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { fadeInOut } from "../../animations/animations";
import { Status } from '../../interface/poll';
import { PublishDialogComponent } from '../publish-dialog/publish-dialog.component';
import { Periods } from '@andreasmeis/period-picker';
import { LanguageService } from 'src/app/services/languageService/language.service';
import { DatePicker } from '../../interface/poll';
import { PollService } from 'src/app/services/pollService/poll.service';

@Component({
  selector: 'app-polls',
  templateUrl: './polls.component.html',
  styleUrls: ['./polls.component.less'],
  animations: [fadeInOut]
})

export class PollsComponent {
  deleteArray: Array<Number> = []
  /* Filtering options */
  searchValue !: string;
  statusValue!: Number
  status: Status[] = [
    { value: 0, viewValue: 'status_0' },
    { value: 1, viewValue: 'status_1' },
    { value: 2, viewValue: 'status_2' }
  ];
  selectedPeriod: any;

  periods!: Periods[];

  /* Table View */
  datasource!: any
  displayedColumns: string[] = ["title", "status", "actions", "select", "delete"]
  selection = new SelectionModel<any>(true, [])
  result: any;

  constructor(private pollService: PollService,private router: Router, public matDialog: MatDialog, private route: ActivatedRoute, private languageService: LanguageService) { 
    this.periods = this.languageService.getPeriods();
  }

  // searchPolls
  searchPolls() {
    this.pollService.searchPolls({searchValue:this.searchValue, statusValue: this.statusValue, selectedPeriod: this.selectedPeriod }).then(resp => {
      this.deleteArray.length = 0;
      this.selection = new SelectionModel<any>(true, []);
      this.datasource = resp.data;
    });
  }

  /** Choose date from chips or date picker  */
  periodChange(period: DatePicker) { 
    if(this.selectedPeriod == period) return;
    this.selectedPeriod =  this.languageService.setCurrentPeriod(period);
    this.searchPolls();
  } 

  // Edit selected poll
  onEditPoll(id: any): void {
    this.router.navigate(['edit-poll', id])
  }

  // Create new Poll
  onCreatePoll(): void {
    this.router.navigateByUrl("/create-poll")
  }

  // Publish Poll
  onPublishPoll(id: number): void {
    this.pollService.publishPoll(id).then( resp => {
      this.searchPolls();
    })
  }

  onOpenCharts(poll: any): void {
    this.router.navigate(['poll-chart', poll.pollId])
  }

  // Check if all rows are selected
  isAllSelected() {
    let numSelected = this.selection.selected.length;
    let numRows = this.datasource.length
    return numSelected === numRows;
  }

  /**
   * @returns The length of the selected array.
   */
  isSomeSelected() {
    return this.selection.selected.length > 0
  }

  /**
   * If some are selected, clear all selections.
   * If all are selected, clear all selections.
   *  If none are selected, select all
   */
  masterToggle() {
    if (this.isSomeSelected()) {
      this.selection.clear()
    }
    else {
      this.isAllSelected() ?
        this.selection.clear() :
        this.datasource.forEach((poll: any) =>
          this.selection.select(poll))
    }
  }

  /**
   * It opens a modal dialog, and if the user clicks the "Publish" button,
   *  it calls the onPublishPoll() function.
   */
  openModal(poll: any) {
    const dialogRef = this.matDialog.open(PublishDialogComponent, {
      data: poll,
    });
    dialogRef.afterClosed().subscribe(dialogResult => {

      if (dialogResult) {
        this.onPublishPoll(poll.pollId)
      }

    })
  }

  sendSSE(id: String) {
    this.pollService.sendSSE(id).then(resp => { });
  }

  onCreateDublicate(poll: any){
    this.router.navigate(['create-dublicate',poll.pollId])
  }

  addDraw(poll: any) {
    this.router.navigate(['create-draw'], { state: {poll:poll,automatic: true} ,relativeTo: this.route})
  }

  onDelete() {
    for (let i = 0; i < this.selection.selected.length; i++) {
      this.deleteArray.push(this.selection.selected[i].pollId)
    }
   this.pollService.deletePoll(this.deleteArray).then(resp => {
      this.searchPolls();
   })
  }
}
